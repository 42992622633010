import axios from "axios"
import { logout } from "../redux/userSlice";
import { store } from "../redux/store";

const baseURL = process.env.REACT_APP_SERVER_URL; 

export const axiosAuthorized = axios.create({ baseURL: baseURL,});

export const axiosInstance = axios.create({baseURL: baseURL,});

axiosAuthorized.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosAuthorized.interceptors.response.use(
    (response) => response,
    (error) => {
        if (
            error.response &&
            error.response.status === 403 &&
            error.response.data.message === "Access forbidden, Invalid token"
        ) { store.dispatch(logout()); }
        return Promise.reject(error);
    }
);

