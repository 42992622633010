import React from 'react'
import AnalyticsMainSection from '../../components/DashboardComponents/AnalyticsMainSection';

const Dashboard = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative ">
      <AnalyticsMainSection />
    </div>
  );
}

export default Dashboard