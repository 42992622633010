import React from "react";
import ReactECharts from "echarts-for-react";

const CommonSymptomsChart = () => {
  const data = [
    { value: 10, name: "Fever" },
    { value: 20, name: "Cough" },
    { value: 15, name: "Fatigue" },
    { value: 25, name: "Shortness of Breath" },
    { value: 18, name: "Headache" },
    { value: 12, name: "Sore Throat" },
    { value: 8, name: "Loss of Taste/Smell" },
    { value: 30, name: "Muscle/Joint Pain" },
  ];

  const colors = data.map((item) => {
    const blueShade = 95 + Math.round((item.value - 15) / 2); 
    return `rgb(26, 49, ${blueShade})`;
  });

  const option = {
    series: [
      {
        name: "Common Symptoms",
        type: "pie",
        radius: [60, 200],
        center: ["50%", "50%"],
        roseType: "area",
        itemStyle: {
          borderRadius: 9,
          color: (params) => colors[params.dataIndex],
        },
        data: data,
      },
    ],
    graphic: [
      {
        type: "text",
        left: "center",
        top: "center",
        style: {
          text: "Symptoms",
          textAlign: "center",
          fill: "#1A315F", // Text color
          fontSize: 18,
          fontWeight: "bold",
        },
      },
    ],
  };

  return (
    <ReactECharts option={option} style={{ height: "500px", width: "100%" }} />
  );
};

export default CommonSymptomsChart;
