import { axiosAuthorized } from "../../api/config";

export const addWhyLifePlus = async (initialState, descId = null) => {
    try {
        const requestConfig = {
            headers: { "Content-Type": "application/json" }
        }
        const requestData = {
            ...initialState
        }
        const url = descId ? `/description/update_homepagedescription/${descId}` : `/description/add_homepagedescription`;
        const response = await axiosAuthorized[descId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}


export const getWhyLifePlus = async () => {
    try {
        const response = await axiosAuthorized.get(`/getdescription`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const deleteWhyLifePlus = async (descId) => {
    try {
        const response = await axiosAuthorized.delete(`/description/delete_homepagedescription/${descId}`);
        return response;
    } catch (error) {
        throw error;
    }
}