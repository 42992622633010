import React, { useCallback, useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { getContactUs } from "../../utils/contactUsUtils/contactUsUtils";
import ContactUsModal from "./ContactUsModal";

const ContactUs = () => {
  const [open, setOpen] = useState(null);
  const [contactUs, setContactUs] = useState(null);
  const setContactUsFn = useCallback(() => {
    getContactUs()
      .then((res) => {
        setContactUs(res);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => setContactUsFn(), [setContactUsFn]);
  const [editData, setEditData] = useState(null);
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative mt-5 ">
      {open && (
        <ContactUsModal
          setContactUsFn={setContactUsFn}
          setEditData={setEditData}
          setOpen={setOpen}
          editData={editData}
        />
      )}
      <div className="flex w-full justify-start gap-2 items-center h-fit ">
        <span className="font-semibold">Contact Us</span>
        <button
          onClick={() => {
            setEditData(contactUs);
            setOpen(true);
          }}
          className="px-3 flex gap-2 items-center  bg-primary rounded-md text-white py-1"
        >
          <BsPlus fontSize={18} />
          <span>Edit</span>
        </button>
      </div>
    </div>
  );
};

export default ContactUs;
