import { useState, useRef } from "react";
import "./ImageInput.css";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { AiOutlineLoading3Quarters } from "react-icons/ai"; // Loading icon
import imageCompression from "browser-image-compression";

const ImageInput = ({ setImage, imageName }) => {
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false); // Loading state

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.1, // Maximum size of 100 KB
      useWebWorker: true,
      initialQuality: 0.1, // Lower initial quality to reduce file size
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return new File([compressedFile], file.name, { type: file.type });
    } catch (error) {
      return file;
    }
  };

  const handleImageInput = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setLoading(true); // Set loading to true before starting compression
      const compressedFile = await compressImage(selectedFile);
      setImage(compressedFile);
      setLoading(false); // Set loading to false after compression is complete
    }
  };

  const handleImageDrop = async (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile) {
      setLoading(true); // Set loading to true before starting compression
      const compressedFile = await compressImage(selectedFile);
      setImage(compressedFile);
      setLoading(false); // Set loading to false after compression is complete
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className="image-input-container"
      onDrop={handleImageDrop}
      onDragOver={handleDragOver}
    >
      <div className="image-input">
        <div className="upload-icon-container">
          {imageName}
        </div>
        <div className="image-input-content">
          <button
            type="button"
            className="image-input-button border-primary gap-2"
            onClick={() => fileInputRef.current?.click()}
            disabled={loading} // Disable button when loading
          >
            {loading ? "Uploading..." : "Upload"}{" "}
            {loading ? <AiOutlineLoading3Quarters className="animate-spin" /> : <AiOutlineCloudUpload
              style={{ width: "20px", height: "20px", color: "gray" }}
            />}
          </button>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageInput}
      />
    </div>
  );
};

export default ImageInput;
