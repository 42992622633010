import { axiosAuthorized } from "../../api/config";

export const getCountsAndDoctorImage = async () => {
    try {
        const response = await axiosAuthorized.get(`/gethomepagecallus`);
        return response.data.data[0];
    } catch (error) {
        throw error
    }
}

export const addCountsAndDoctorImage = async (initialState, doctor_icon, id = null) => {
    try {
        const requestData = {
            ...initialState,
            doctor_icon,
        }
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };
        const url = id
            ? `/homepagecallus/update_homepagecallus/${id}`
            : `/homepagecallus/add_homepagecallus`;
        const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
};
