import React, { useCallback, useEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import HomePageFooterModal from './HomePageFooterModal';
import { getFooterData } from '../../utils/homePageFooterUtils/homePageFooterUitls';

const HomePageFooter = () => {
    const [open, setOpen] = useState(null);
    const [footerData, setFooterData] = useState(null);
    const setFooterDataFn = useCallback(() => {
        getFooterData().then(res => { setFooterData(res)}).catch(err => console.log(err))
    }, []);
    useEffect(() => setFooterDataFn(), [setFooterDataFn]);
    const [editData, setEditData] = useState(null);
    return (
      <div className="w-full h-fit flex flex-col gap-5 relative mt-5 ">
        {open && (
          <HomePageFooterModal
            setFooterDataFn={setFooterDataFn}
            setEditData={setEditData}
            setOpen={setOpen}
            editData={editData}
          />
        )}
        <div className="flex w-full justify-start gap-2 items-center h-fit ">
          <span className="font-semibold">Home Page Footer</span>
          <button
            onClick={() => {
              setEditData(footerData);
              setOpen(true);
            }}
            className="px-3 flex gap-2 items-center  bg-primary rounded-md text-white py-1"
          >
            <BsPlus fontSize={18} />
            <span>Edit</span>
          </button>
        </div>
      </div>
    );
}

export default HomePageFooter