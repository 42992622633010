import React from "react";
import "./TextInput.css";

const TextInput = ({ name = "", formName, data, handleInput, setError, hide=false }) => {
  return (
    <div className="lable-and-filed">
      {name.length > 0 && (
        <label htmlFor={name} className="label">
          {name} <span hidden={hide} className="text-red-600">*</span>
        </label>
      )}
      <div className="input-coantiner" onClick={() => setError({ global: "" })}>
        <input
          type="text"
          name={formName}
          id={formName}
          value={data[formName]}
          onChange={handleInput}
          placeholder={name}
          className="input"
        />
      </div>
    </div>
  );
};

export default TextInput;
