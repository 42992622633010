export const validateCouponData = (initialState, setError) => {

    const datePattern = /^\d{4}-\d{2}-\d{2}$/; 
    const categoryPattern = /^[A-Za-z\s]+$/;
    const amountPattern = /^\d+(\.\d{1,2})?$/; 
    const percentagePattern = /^\d{1,2}(\.\d{1,2})?$/;
    
    if (!categoryPattern.test(initialState.category) || initialState.category === "Select Category") {
        setError({ global: "Invalid Category" });
        return false;
    }
    if (!datePattern.test(initialState.date)) {
        setError({ global: "Invalid Date" });
        return false;
    }

    if (!amountPattern.test(initialState.amount)) {
        setError({ global: "Invalid Amount" });
        return false;
    }

    
    if (!percentagePattern.test(initialState.percentage)) {
        setError({ global: "Invalid Percentage" });
        return false;
    }

    return true;
};

