export const validateCallUsPoint = (initialState, homepage_icon, setErr) => {
    if (!initialState.home_description.trim().length) {
        setErr({ global: "Invalid Description" });
        return false;
    }
    if (!homepage_icon) {
        setErr({ global: "Select Icon Image" });
        return false;
    }
    return true;
}