import React, { useCallback, useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import { SearchInput } from "../Common";
import { BsArrowLeftShort } from "react-icons/bs";
import { useSelector } from "react-redux";
import CareerTable from "./CareerTable";
import CareerModal from "./CareerModal";
import { getAllJobs } from "../../utils/careerUtils/careerUtils";
import ApplicantsList from "./ApplicantsList";

const CareerMainSection = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [job, setJob] = useState(null);
  const [jobs, setJobs] = useState([]);
  const setJobsFn = useCallback(() => {
    getAllJobs()
      .then((res) => setJobs(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setJobsFn();
  }, [setJobsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = jobs?.filter((job) => {
      const nameMatch = job?.job_title
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const qualificationMatch = job?.qualification
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || qualificationMatch;
    });
    setFilteredData(filteredData);
  }, [jobs, searchQuery]);
  const user = useSelector((state) => state.userReducer.admin);
  return (
    <>
      {open && (
        <CareerModal
          setEditData={setEditData}
          setJobsFn={setJobsFn}
          setOpen={setOpen}
          editData={editData}
        />
      )}
      <div className=" flex w-full items-center gap-5 md:gap-0">
        <div className="flex w-fit h-fit text-xl font-semibold">Career</div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search By Job Title, Qualification"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <button
            disabled={!user.access.includes("Edit")}
            className={`bg-secondary text-white ${
              !user.access.includes("Edit") ? "hidden" : "flex"
            } text-sm rounded-md gap-2 p-2 active:bg-secondary  items-center`}
            onClick={() => setOpen(true)}
          >
            <span>
              <FaUserPlus />
            </span>
            <span>Job</span>
          </button>
        </div>
        {job && (
          <button
            className={`text-secondary w-fit flex text-sm rounded-md gap-2 p-2 active:text-primary  items-center`}
            onClick={() => setJob(null)}
          >
            <span>
              <BsArrowLeftShort />
            </span>
            <span>Back</span>
          </button>
        )}
        {job ? (
          <ApplicantsList job={job} />
        ) : (
          <CareerTable
            jobs={filterdData}
            setJobsFn={setJobsFn}
            setEditData={setEditData}
            setOpen={setOpen}
            user={user}
            setJob={setJob}
          />
        )}
      </div>
    </>
  );
};

export default CareerMainSection;
