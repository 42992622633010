import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from '../../hooks';
import {  ImageInput, MapPackages, ShowImage, TextInput } from '../Common/FormComponents';
import { validatePackageForm } from '../../utils/treamentUtils/validatePackageForm';
import { addPackage, deletePackage, getPackages } from '../../utils/treamentUtils/treatmentUtils';
import JoditEditor,{Jodit} from 'jodit-react';

const config = {
  readonly: false,
  placeholder: "Benifits",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const PackageSection = ({selectedTreatment}) => {
    const packageInitailState = {
      name: "",
      duration: "",
      price: "",
    };
    const editor = useRef(null);
    const [benefits, setBenifits] = useState("<p></p>");
    const [packages, setPackages] = useState([]);
    const setPackagesFn = useCallback(() => {
        getPackages(selectedTreatment?._id).then((res) => setPackages(res.data.data)).catch(err=>console.log(err));
    },[selectedTreatment?._id]);
    useEffect(() => {
        setPackagesFn();
    }, [setPackagesFn]);
    const removePackage = (packageId) => {
    deletePackage(packageId).then(res => setPackagesFn()).catch(err => console.log(err));
    };
    const [packageData, handlePackageData, clearPackageData] = useForm(packageInitailState);
    const [packageImage, setPackageImage] = useState(null);
    const [err,setErr] = useState({package:""})
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validatePackageForm(packageData,benefits, packageImage, setErr)) {
            setLoading(true);
            addPackage(packageData,benefits, selectedTreatment?._id, packageImage)
              .then(res => { setBenifits("<p></p>");setPackagesFn();  clearPackageData(); setPackageImage(null); setErr({ package: "" }); })
                .catch(err => { console.log(err) })
                .finally(() => { setLoading(false) });
        }
    }
  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full h-fit items-center justify-between font-semibold">
        <span>{"Add Packages"}</span>
      </div>
      <MapPackages packages={packages} removePackage={removePackage} />
      <div className="feild-conatainer">
        <TextInput
          data={packageData}
          handleInput={handlePackageData}
          name={"Package"}
          formName={"name"}
          setError={setErr}
        />
      </div>
      <div className="flex flex-col w-full gap-0.5">
        <label htmlFor={"Answer"} className="label">
          {"Answer"} <span className="text-red-600">*</span>
        </label>
        <JoditEditor
          ref={editor}
          value={benefits}
          config={config}
          tabIndex={1}
          onBlur={(newContent) =>
            setBenifits(
              newContent.replace(
                /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                '<td style="border: 1px solid black;" >'
              )
            )
          }
        />
      </div>
      <div className="feild-conatainer">
        <TextInput
          data={packageData}
          handleInput={handlePackageData}
          name={"Duration"}
          formName={"duration"}
          setError={setErr}
        />
        <TextInput
          data={packageData}
          handleInput={handlePackageData}
          name={"Price"}
          formName={"price"}
          setError={setErr}
        />
      </div>
      <div className="feild-conatainer">
        <ShowImage image={packageImage} setImage={setPackageImage} />
        <ImageInput imageName={"Package Image"} setImage={setPackageImage} />
        <div className="flex bg-slate-200 rounded-md w-fit h-fit">
          <button
            disabled={loading}
            className="p-1 border rounded-md -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Add
          </button>
        </div>
      </div>
      {err.package && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.package}
        </div>
      )}
    </div>
  );
}

export default PackageSection