import React from 'react'
import CommonSymptomsChart from './CommonSymptomsChart';

const CommonSymptoms = () => {
  return (
    <div className="flex flex-col w-full h-full ">
      <div className="flex w-full h-fit text-xl font-bold text-primary px-5">
        Common Symptoms
      </div>
      <div className="w-full h-full pb-5">
        <CommonSymptomsChart />
      </div>
    </div>
  );
}

export default CommonSymptoms