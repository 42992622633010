import { axiosInstance } from "../../api/config";

export const getMetaTitleDescriptionTags = async () => {
  try {
    const response = await axiosInstance.get(`/tag/get_tag`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addMetaTitleDescriptionTags = async (initialState, meta_tags, id = null) => {
  try {
    const requestData = {
      ...initialState,
      meta_tags,
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/tag/update_tag/${id}`
      : `/tag/add_tag`;

    const response = await axiosInstance[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteMetaTitleDescriptionTags = async (id) => {
  try {
    const response = await axiosInstance.delete(`/tag/delete_tag/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}