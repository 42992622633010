import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NoDataFound, SearchInput, Table } from "../Common";
import { getLabs } from "../../utils/labUtils/labUtils";
import DateInput from "../Common/FormComponents/DateInput/DateInput";
import { FaFileDownload } from "react-icons/fa";
import * as XLSX from "xlsx";
import { useForm } from "../../hooks";

const LabDataSection = () => {
  const headings = useMemo(() => ["Name", "Owner", "Email", "Phone"], []);
  const [labs, setLabs] = useState([]);
  const setLabsFn = useCallback(() => {
    getLabs()
      .then((res) => setLabs(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setLabsFn();
  }, [setLabsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdLabs, setFilteredLabs] = useState([]);
  const [dateData, handleDateData, clearDateData] = useForm({
    start: "",
    end: "",
  });
  useEffect(() => {
    const filteredData = labs?.filter((lab) => {
      const nameMatch = lab?.name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const ownerMatch = lab?.owner_name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const phoneMatch = lab?.phone_number
        ?.toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const emailMatch = lab?.email_id
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const dateMatch = () => {
        if (dateData.start && dateData.end) {
          const dateObject = new Date(lab.createdAt)
            .toLocaleString()
            .split(",")[0];
          const startDateMatch =
            Date.parse(dateData.start) <= Date.parse(dateObject);
          const endDateMatch =
            Date.parse(dateData.end) >= Date.parse(dateObject);
          return startDateMatch && endDateMatch;
        }
        return true;
      };
      return (
        dateMatch() && (nameMatch || ownerMatch || phoneMatch || emailMatch)
      );
    });
    setFilteredLabs(filteredData);
  }, [labs, searchQuery, dateData]);
  const setError = useState({ global: "" })[1];
  const exportToXLSX = (data, headings) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Doctors Data");
    XLSX.writeFile(workbook, "Doctorsdata.xlsx");
  };
  return (
    <div className="h-full  w-full mt-3">
      <div className="flex w-full justify-center flex-col items-center mb-5 gap-2">
        <div className="flex w-full sm:w-1/2">
          <SearchInput
            placeholder={"Search by Lab name, Owner name, Phone, Email"}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        <div className="flex w-full justify-between flex-col sm:flex-row gap-3">
          <div className="flex  gap-2 items-end ">
            <DateInput
              name="Start"
              data={dateData}
              formName={"start"}
              handleInput={handleDateData}
              setError={setError}
            />
            <DateInput
              name="End"
              data={dateData}
              formName={"end"}
              handleInput={handleDateData}
              setError={setError}
            />
            {(dateData.start.length > 0 || dateData.end.length > 0) && (
              <div className="flex w-fit h-fit  mb-2 bg-slate-300 rounded">
                <button
                  onClick={() => clearDateData()}
                  className="text-xs translate-x-1 -translate-y-1 hover:translate-x-0 hover:translate-y-0 bg-white py-0.5 px-1 rounded  duration-500 text-primary border"
                >
                  Clear
                </button>
              </div>
            )}
          </div>
          <div
            className={`${
              filterdLabs?.length > 0 ? "flex" : "hidden"
            } gap-2 items-end `}
          >
            <button
              className="text-white bg-primary text-base rounded-md px-3 py-1 h-fit flex gap-1 items-center"
              onClick={() => exportToXLSX(filterdLabs, headings)}
            >
              Download <FaFileDownload />
            </button>
          </div>
        </div>
      </div>
      {filterdLabs?.length > 0 ? (
        <Table headings={headings}>
          {filterdLabs?.map((lab, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center gap-4">
                  <div className="flex-shrink-0 h-9 w-9 justify-center items-center border rounded-full flex">
                    <img
                      className="rounded-full h-9 w-9 object-cover"
                      src={lab?.lab_profile_image}
                      alt="lab_profile"
                    />
                  </div>
                  <div className="text-sm font-medium text-gray-900">
                    {lab?.name}
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {lab?.owner_name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">
                  {lab?.email_id.slice(0, 10) + "..."}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {lab?.phone_number}
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"labs"} />
      )}
    </div>
  );
};

export default LabDataSection;
