import React from 'react'
import PharmacyMainSection from '../../components/PharmacyComponents/PharmacyMainSection';
const Pharmacies = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <PharmacyMainSection />
    </div>
  );
}

export default Pharmacies