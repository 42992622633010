import React, { useCallback, useEffect, useState } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { NoDataFound } from "../Common";
import { CiEdit } from "react-icons/ci";
import { BsPlus } from "react-icons/bs";
import { deleteIcon, getOurValues } from "../../utils/aboutUsUtils/aboutUsUtils";
import OurValuesModal from "./OurValuesModal";

const OurValues = () => {
  const [open, setOpen] = useState(false);
  const [ourValues, setOurValues] = useState([]);
  const setOurValuesFn = useCallback(() => {
    getOurValues()
      .then((res) => setOurValues(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setOurValuesFn();
  }, [setOurValuesFn]);
  const [editData, setEditData] = useState(null);
    const delValue = (valueId) => {
    deleteIcon(valueId).then((res) => setOurValuesFn()).catch(err=>console.log(err));
    };
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative mt-5 ">
      {open && (
        <OurValuesModal
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
          setOurValuesFn={setOurValuesFn}
        />
      )}
      <div className="flex w-full justify-start gap-2 items-center h-fit">
        <span className="font-semibold">Our Values</span>
        <button
          onClick={() => setOpen(true)}
          className="px-3 flex gap-2 items-center  bg-primary rounded-md text-white py-1"
        >
          <BsPlus fontSize={18} />
          <span>Add</span>
        </button>
      </div>
      {ourValues?.length > 0 ? (
        <div className=" flex h-fit items-center w-full flex-col ">
          <div className="flex flex-col w-full mt-3 max-h-[400px]">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-blue-100">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                        >
                          Edit
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                        >
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 max-h-[400px] overflow-y-auto">
                      {ourValues.map((value, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center gap-4">
                              <div className="flex-shrink-0  h-16 w-16  justify-center items-center  rounded-md flex">
                                <img
                                  className="rounded-md h-16 w-16 object-cover"
                                  src={value?.icon}
                                  alt="value_image"
                                />
                              </div>
                              <div className="text-sm text-gray-900 capitalize max-w-sm truncate">
                                {value?.icon_name}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div
                              className="text-sm text-gray-900 capitalize cursor-pointer"
                              onClick={() => {
                                setEditData(value);
                                setOpen(true);
                              }}
                            >
                              <CiEdit color="black" />
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div
                              className="text-sm text-gray-900 capitalize cursor-pointer"
                              onClick={() => {
                                delValue(value?._id);
                              }}
                            >
                              <MdOutlineDeleteOutline color="red" />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoDataFound data={"values"} />
      )}
    </div>
  );
};

export default OurValues;
