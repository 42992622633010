export const validateMemberForm = (initialState , image, pastCompany,  setErr) => {
    const experiencePattern = /^\d+$/;
    if (!initialState.name.trim().length) {
        setErr({ global: "Invalid Name" });
        return false;
    }

    if (!initialState.role.trim().length) {
        setErr({ global: "Invalid Role" });
        return false;
    }

    if (!initialState.role.trim().length) {
        setErr({ global: "Invalid Role" });
        return false;
    }

    if (!experiencePattern.test(initialState.experience)) {
        setErr({ global: "Invalid Experience" });
        return false;
    }
    
    if (!image) {
        setErr({ global: "Select Profile Image" });
        return false;
    }

    if (!pastCompany) {
        setErr({ global: "Select Past Company Image" });
        return false;
    }
    return true;
}
export const validateIconForm = (initialState , image,  setErr) => {

    if (!initialState.icon_name.trim().length) {
        setErr({ global: "Invalid Name" });
        return false;
    }
    
    if (!image) {
        setErr({ global: "Select Profile Image" });
        return false;
    }

    return true;
}