import React, {  useEffect, useState } from 'react'
import { useForm } from '../../hooks';
import { GrFormClose } from 'react-icons/gr';
import { ImageInput, SelectInput, ShowImage, TextInput } from '../Common/FormComponents';
import { addSymtom } from '../../utils/symtomUtils/symtomUtils';
import { validateSymptomForm } from '../../utils/symtomUtils/validateSymtomForm';
import { toast } from 'react-toastify';
import { getTreatments } from '../../utils/treamentUtils/treatmentUtils';
const SymtomsModal = ({ setOpen, editData = null, setEditData, setSymtomsFn }) => {
    const symtomInitialState = {
      name: editData?.name || "",
      sub_department_id: editData?.sub_department_id?.sub_department_name || "",
    };
    const [symtomData, handleSymtomData, clearForm] = useForm(symtomInitialState);
    const [treamtments, setTreatments] = useState([]);
    const [treats, setTreats] = useState([]);
    const [treatId, setTreatId] = useState(0);
    useEffect(() => {
    getTreatments()
      .then((res) => {
        setTreatments([
          { sub_department_name: "Select Treatment", _id: 0 },
          ...res.data.data,
        ]);
        setTreats([
          "Select Treatment",
          ...res.data.data.map((item) => item.sub_department_name),
        ]);
      })
      .catch((err) => {
        console.log(err);
        setTreatments([{ sub_department_name: "Select Treatment", _id: 0 }]);
      });
    }, []);
    useEffect(() => {
       const foundTreatment = treamtments.find(
         (treat) => treat.sub_department_name === symtomData.sub_department_id
       );
       if (foundTreatment) {
         setTreatId(foundTreatment._id);
       }
    }, [symtomData.sub_department_id, treamtments]);
    const [image, setImage] = useState(editData?.new_symptom_image || null);
    const [err, setErr] = useState({ global: "" });
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
      if (validateSymptomForm(symtomData,treatId, image, setErr)) {
          setErr({ global: "" });
            setLoading(true);
            addSymtom(symtomData,treatId, image, editData?._id)
              .then(res => {
              console.log(res);
              setSymtomsFn(); setImage(null); clearForm();
              toast(`Symptom ${editData ? "Edited" : "Added"}`, {
              type: "success",
              autoClose: 3000,
              }); 
              setOpen(false) })
              .catch(err => {
              console.log(err);
              toast(`Symptom ${editData ? "edit failed" : "add failed"}`, {
              type: "error",
              autoClose: 3000,
            });
            })
            .finally(() => setLoading(false));
        }
    };
  return (
    <div className="flex bg-white   top-0 left-0 w-full max-w-screen-sm overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
      <div className="flex w-full h-fit  items-center justify-between font-semibold">
        <span>{editData ? "Edit Symptom" :"Add new Symptom"}</span>
        <span
          className="cursor-pointer"
          onClick={() => {
            setOpen(false);
            setEditData(null);
          }}
        >
          <GrFormClose fontSize={16} />
        </span>
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          name={"Name"}
          formName={"name"}
          data={symtomData}
          handleInput={handleSymtomData}
          setError={setErr}
        />
        <SelectInput
          name={"Treatment"}
          formName={"sub_department_id"}
          data={symtomData}
          handleInput={handleSymtomData}
          items={treats}
          setError={setErr}
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <ShowImage image={image} setImage={setImage} />
        <ImageInput imageName={"Symtom Image"} setImage={setImage} />
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5">
        <div className=" flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            Cancel
          </button>
        </div>
        <div className="flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
            disabled={loading}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default SymtomsModal