import { axiosAuthorized } from "../../api/config";

export const getHomeImages = async () => {
    try {
        const response = await axiosAuthorized.get("/homepage/get_homepageslider");
        return response;
    } catch (error) {
        throw error;
    }
}

export const addHomeImage = async(image) => {
    try {
        const response = await axiosAuthorized.post("/homepage/add_homepageslider", {
            home_page_slider: image
        }, {
            headers: {
            "Content-Type":"multipart/form-data"
        }});
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteHomeImage = async (imageId) => {
    try {
        const response = await axiosAuthorized.delete(`/homepage/delete_homepageslider/${imageId}`);
        return response;
    } catch (error) {
        throw error;
    }
}