import React from 'react'

const MostVisitedDepartments = () => {
  return (
    <div className="flex flex-col w-full h-full  ">
      <div className="flex w-full h-fit text-xl font-bold text-primary px-5">
        Most Visited Department
      </div>
      <div className="flex w-fit h-full p-5">
        <div className="hidden md:flex  h-full items-center justify-center w-fit ">
          <img src="/assets/images/polygon-logo.png" alt="polygon-logo" />
        </div>
        <div className="hidden md:flex h-full items-center justify-center w-fit ">
          <img
            src="/assets/images/analytics-top-departments.svg"
            alt="analytics-top-departments-svg"
          />
        </div>
        <div className=" w-full h-full flex flex-col gap-3 md:ps-5 justify-around">
          <div className="flex w-full h-fit border gap-3 py-2 px-3 rounded-lg shadow-lg justify-between  items-center">
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/department-name.svg"
                alt="department-name-svg"
              />
              <span className="text-xs font-semibold">Umbilical Hernia</span>
            </div>
            <div className="w-[0.5px] mx-5 h-full py-3">
              <div className="w-full h-full bg-gray-300"></div>
            </div>
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/deparmtent-doctor.svg"
                alt="department-doctor-svg"
              />
              <span className="text-xs font-semibold">Dr Sajeet Nayar</span>
            </div>
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/deparmtent-doctor.svg"
                alt="department-doctor-svg"
              />
              <span className="text-xs font-semibold">Dr Sajeet Nayar</span>
            </div>
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/deparmtent-doctor.svg"
                alt="department-doctor-svg"
              />
              <span className="text-xs font-semibold">Dr Sajeet Nayar</span>
            </div>
          </div>
          <div className="flex w-full h-fit border gap-3 py-2 px-3 rounded-lg shadow-lg justify-between  items-center">
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/department-name.svg"
                alt="department-name-svg"
              />
              <span className="text-xs font-semibold">Umbilical Hernia</span>
            </div>
            <div className="w-[0.5px] mx-5 h-full py-3">
              <div className="w-full h-full bg-gray-300"></div>
            </div>
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/deparmtent-doctor.svg"
                alt="department-doctor-svg"
              />
              <span className="text-xs font-semibold">Dr Sajeet Nayar</span>
            </div>
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/deparmtent-doctor.svg"
                alt="department-doctor-svg"
              />
              <span className="text-xs font-semibold">Dr Sajeet Nayar</span>
            </div>
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/deparmtent-doctor.svg"
                alt="department-doctor-svg"
              />
              <span className="text-xs font-semibold">Dr Sajeet Nayar</span>
            </div>
          </div>
          <div className="flex w-full h-fit border gap-3 py-2 px-3 rounded-lg shadow-lg justify-between  items-center">
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/department-name.svg"
                alt="department-name-svg"
              />
              <span className="text-xs font-semibold">Umbilical Hernia</span>
            </div>
            <div className="w-[0.5px] mx-5 h-full py-3">
              <div className="w-full h-full bg-gray-300"></div>
            </div>
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/deparmtent-doctor.svg"
                alt="department-doctor-svg"
              />
              <span className="text-xs font-semibold">Dr Sajeet Nayar</span>
            </div>
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/deparmtent-doctor.svg"
                alt="department-doctor-svg"
              />
              <span className="text-xs font-semibold">Dr Sajeet Nayar</span>
            </div>
            <div className="flex w-fit h-fit flex-col  items-center justify-center ">
              <img
                src="/assets/images/deparmtent-doctor.svg"
                alt="department-doctor-svg"
              />
              <span className="text-xs font-semibold">Dr Sajeet Nayar</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MostVisitedDepartments