export const validateCallUs = (initialState, doctor_icon, setErr) => {
    const numberPattern = /^[0-9]+$/;
    if (!numberPattern.test(initialState.call_us)) {
        setErr({ global: "Invalid Number" });
        return false;
    }
    if (!numberPattern.test(initialState.happy_patient_count)) {
        setErr({ global: "Invalid Happy clients count" });
        return false;
    }
    if (!numberPattern.test(initialState.surgery_count)) {
        setErr({ global: "Invalid Surgery count" });
        return false;
    }
    if (!numberPattern.test(initialState.doctor_count)) {
        setErr({ global: "Invalid Doctor count" });
        return false;
    }
    if (!numberPattern.test(initialState.disease_count)) {
        setErr({ global: "Invalid Disease count" });
        return false;
    }
    if (!doctor_icon) {
        setErr({ global: "Select Doctor Image" });
        return false;
    }
    return true;
}