import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AboutUsTable from "./AboutTable";
import AboutUsModal from "./AboutUsModal";
import { getOurTeam } from "../../utils/aboutUsUtils/aboutUsUtils";
import OurAdversoryBoard from "./OurAdversoryBoard";
import { BsPlus } from "react-icons/bs";
import ProblemsWeAreSolving from "./ProblemsWeAreSolving";
import OurValues from "./OurValues";
import OurServices from "./OurServices";
import SocaialChannelsAndCommunity from "./SocialChannelsAndCommunity";
import ContactUs from "./ContactUs";
import Numbers from "./Numbers";
import PrivacyAndPolicy from "./PrivacyAndPolicy";
import TermsAndConditions from "./TermsAndConditions";
import CancellationReturnPolicy from "./CancellationReturnPolicy";

const AboutUsMainSection = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [aboutUs, setAboutUs] = useState([]);
  const setAboutUsFn = useCallback(() => {
    getOurTeam()
      .then((res) => setAboutUs(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setAboutUsFn();
  }, [setAboutUsFn]);
  const user = useSelector((state) => state.userReducer.admin);
  return (
    <div className="pb-10">
     <div className="w-full h-fit flex flex-col gap-5 relative mt-5 ">
      {open && (
          <AboutUsModal
          setAboutUsFn={setAboutUsFn}
          setEditData={setEditData}
          setOpen={setOpen}
          editData={editData}
        />
      )}
      <div className="flex w-full justify-start gap-2 items-center h-fit">
        <span className="font-semibold">Our Team</span>
        <button
          onClick={() => setOpen(true)}
          className="px-3 flex gap-2 items-center  bg-primary rounded-md text-white py-1"
        >
          <BsPlus fontSize={18} />
          <span>Add</span>
        </button>
      </div>
      <AboutUsTable
        aboutUs={aboutUs}
        setAboutUsFn={setAboutUsFn}
        setEditData={setEditData}
        setOpen={setOpen}
        user={user}
        />
      </div>
      <OurAdversoryBoard />
      <ProblemsWeAreSolving/>
      <OurValues/>
      <OurServices/>
      <SocaialChannelsAndCommunity/>
      <Numbers />
      <PrivacyAndPolicy />
      <TermsAndConditions />
      <CancellationReturnPolicy/>
      <ContactUs/>
    </div>
  );
};

export default AboutUsMainSection;
