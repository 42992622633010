import React, { useMemo } from "react";
import { CiEdit } from "react-icons/ci";
import { deleteTreatment } from "../../utils/treamentUtils/treatmentUtils";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { NoDataFound } from "../Common";


const TreatmentsTable = ({ setEditData, setOpen, treatments, setTreatmentFn, user }) => {
  const delTreatment = (treatmentId) => {
    deleteTreatment(treatmentId).then(res => setTreatmentFn ()).catch(err => console.log(err));
  }
  const tableHeadings = useMemo(
    () => {
      const defaultHeadings = ["Name", "Department"];
      const userHasEditAccess = user.access.includes("Edit");
      const userHasDeleteAccess = user.access.includes("Delete");
      const headings = [...defaultHeadings];
      if (userHasEditAccess) {
        headings.push("Edit");
      }
      if (userHasDeleteAccess) {
        headings.push("Delete");
      }
      return headings;
    },
    [user]
  );
  return (
    <div className="flex h-fit items-center w-full flex-col mt-5">
      <div className="flex flex-col w-full mt-3 max-h-screen">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {treatments?.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      {tableHeadings?.map((heading, index) => (
                        <th
                          scope="col"
                          key={index}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-screen overflow-y-auto">
                    {treatments?.map((treatment, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {treatment?.sub_department_name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize max-w-xs truncate">
                            {treatment?.department_id?.department}
                          </div>
                        </td>
                        <td
                          className={`${
                            !user?.access.includes("Edit") && "hidden"
                          } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              setEditData(treatment);
                              setOpen(true);
                            }}
                          >
                            <CiEdit />
                          </div>
                        </td>
                        <td
                          className={`${
                            !user?.access.includes("Delete") && "hidden"
                          } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              delTreatment(treatment?._id);
                            }}
                          >
                            <MdOutlineDeleteOutline color="red" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound data={"treatments"} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentsTable;
