import React, { useState } from 'react'
import { useForm } from '../../hooks';
import { GrFormClose } from 'react-icons/gr';
import { ImageInput, ShowImage, TextInput } from '../Common/FormComponents';
import { addInsuranceProvider } from '../../utils/insuranceProviderUtils/insuranceProviderUtils';
import { validateSymptomForm } from '../../utils/symtomUtils/validateSymtomForm';
import { toast } from 'react-toastify';

const InsuranceProviderModal = ({ setOpen, editData = null,setEditData, setProvidersFn }) => {
    const providerInitialData = {
      name: editData?.name || "",
      link: editData?.link || "",
    };
    const [providerData, handleProviderData, clearForm] = useForm(providerInitialData);
    const [image, setImage] = useState(editData?.insurance_image || null);
    const [err, setErr] = useState({ global: "" });
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
      if (validateSymptomForm(providerData, image, setErr)) {
          setErr({ global: "" });
            setLoading(true);
            addInsuranceProvider(providerData, image, editData?._id)
              .then(res => {
                setProvidersFn(); setImage(null); clearForm();
                toast(`Insurance Proiver ${editData ? "Edited" : "Added"}`, {
                  type: "success",
                  autoClose: 3000,
                });
                setOpen(false)
              })
              .catch(err => {
                console.log(err);
              toast(`Insurance Proiver ${editData ? "edit failed" : "add failed"}`, {
                type: "error",
                autoClose: 3000,
              });
              })
            .finally(() => setLoading(false));
        }
    };
  return (
     <div className="flex bg-white   top-0 left-0 w-full max-w-screen-sm overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit  items-center justify-between font-semibold">
            <span>{"Add new Insurance Provider"}</span>
            <span className="cursor-pointer" onClick={() => { setOpen(false); setEditData(null); }}><GrFormClose fontSize={16}/></span>
        </div> 
        <div className="flex w-full h-fit justify-between gap-5">
        <TextInput name={"Name"} formName={"name"} data={providerData} handleInput={handleProviderData} setError={setErr}/>
        <TextInput name={"Link"} formName={"link"} data={providerData} handleInput={handleProviderData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
        <ShowImage image={image} setImage={setImage}/>
        <ImageInput imageName={"Provider Image"} setImage={setImage}/>
        </div>
        {err.global && (<div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">{err.global}</div>)}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
                  <button className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center" onClick={() => { setOpen(false); setEditData(null);  }}>
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center " disabled={loading} onClick={(e) => {handleSubmit(e);}}>
              Submit
            </button>
          </div>
        </div>
      </div>
  )
}

export default InsuranceProviderModal