export const validateContactUsForm = (initialState, hospital_location, banner_images, setErr) => {
    if (!initialState.banner_title.trim().length) {
        setErr({ global: "Invalid Title" });
        return false;
    }
    if (!initialState.banner_description.trim().length) {
        setErr({ global: "Invalid Description" });
        return false;
    }
    if (!hospital_location.length) {
        setErr({ global: "Add At Least One Location" });
        return false;
    }
    if (!banner_images) {
        setErr({ global: "Please Select Banner Image" });
        return false;
    }
    return true;
}