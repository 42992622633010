import React, { useState } from "react";
import { useForm } from "../../hooks";
import { GrFormClose } from "react-icons/gr";
import {
  ImageInput,
  NumberInput,
  ShowImage,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { addCountsAndDoctorImage } from "../../utils/countsAndDoctorImageUtils/countsAndDoctorImageUtils";
import { validateCallUs } from "../../utils/countsAndDoctorImageUtils/validateCallUs";

const CountsAndDoctorImageModal = ({
  setOpen,
  editData = null,
  setEditData,
  setCallUsFn,
}) => {
  const initialState = {
    call_us: editData?.call_us || "",
    happy_patient_count: editData?.happy_patient_count || 0,
    surgery_count: editData?.surgery_count || 0,
    doctor_count: editData?.doctor_count || 0,
    disease_count: editData?.disease_count || 0,
  };
  const [err, setError] = useState({ global: "" });
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [doctor_icon, setDoctorImage] = useState(
    editData?.doctor_icon || null
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateCallUs(formData, doctor_icon, setError)) {
      setError({ global: "" });
      setLoading(true);
      addCountsAndDoctorImage(formData, doctor_icon, editData?._id)
        .then((res) => {
          toast(`Call us ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
          setEditData(null);
          clearForm();
          setDoctorImage(null);
          setCallUsFn();
          setOpen(false);
        })
        .catch((err) => {
          toast(`Call us ${editData ? "edit" : "add"} failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="modal-container min-h-[400px] z-10">
      <div className="modal">
        <div className="modal-header">
          <span>Edit Call Us</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Call Us Number"}
            formName={"call_us"}
            setError={setError}
          />
          <NumberInput
            data={formData}
            handleInput={handleInput}
            name={"Happy Patients"}
            formName={"happy_patient_count"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          <NumberInput
            data={formData}
            handleInput={handleInput}
            name={"Surgery Count"}
            formName={"surgery_count"}
            setError={setError}
          />
          <NumberInput
            data={formData}
            handleInput={handleInput}
            name={"Doctor Count"}
            formName={"doctor_count"}
            setError={setError}
          />
          <NumberInput
            data={formData}
            handleInput={handleInput}
            name={"Disease Count"}
            formName={"disease_count"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          {
            <>
              <ShowImage image={doctor_icon} setImage={setDoctorImage} />
              <ImageInput
                image={doctor_icon}
                setImage={setDoctorImage}
                imageName={"Doctor Image"}
              />
            </>
          }
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountsAndDoctorImageModal;
