import React, { useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { ImageInput, SelectInput, ShowImage } from '../Common/FormComponents';
import { toast } from 'react-toastify';
import { addOfferImage } from '../../utils/sliderUtils/offerSliderUtils';
import useForm from '../../hooks/useForm';

const OfferSliderModal = ({setOpen,setOfferImagesFn}) => {
  const [err, setErr] = useState({ global: "" });
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const initialState = { category: "Select Category" };
  const [offerData, handleOfferData, clearForm] = useForm(initialState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (offerData.category === "Select Category") return setErr({ global: "Select Catgory" });
    if (!image) return setErr({ global: "Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addOfferImage(offerData,image)
      .then((res) => {
        setOfferImagesFn();
        setImage(null);
        clearForm();
        setOpen(false);
        toast(`Offer image added`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast(`OFfer image adding failed`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-full  rounded-md justify-start items-start bg-white">
      <div className=" flex bg-white w-full overflow-y-auto h-fit border shadow-lg  rounded-sm gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{"Add Offer Image"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <SelectInput
            name="Category"
            formName={"category"}
            data={offerData}
            handleInput={handleOfferData}
            items={["Select Category", "Consulation", "Lab", "Pharmacy"]}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowImage image={image} setImage={setImage} />
          <ImageInput imageName={"Offer Image"} setImage={setImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setImage(null);
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferSliderModal