import { axiosAuthorized } from "../../api/config"

export const getDepartments = async () => {
  try {
    const response = await axiosAuthorized.get("/department/get_department");
    return response;
  } catch (error) {
    throw error;
  }
}

export const addDepartment = async (department, department_description, department_image, department_content_image, meta_name, meta_description, meta_tag, slug, departmentId = null) => {
  try {
    const data = {
      department,
      slug,
      department_description,
      meta_name,
      meta_description,
      meta_tag
    };
    const requestData = departmentId ? data : {
      ...data,
      department_image,
      department_content_image
    };
    const requestConfig = {
      headers: { "Content-Type": departmentId ? "application/json" : "multipart/form-data" },
    };
    const url = departmentId
      ? `/department/update_department/${departmentId}`
      : `/department/add_department`;
    const mainRequest = axiosAuthorized[departmentId ? 'put' : 'post'](url, requestData, requestConfig);
    const imageRequests = [];
    if (departmentId && department_image instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/department/update_departmentimage/${departmentId}`, { department_image }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    if (departmentId && department_content_image instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/department/update_departmentcontentimage/${departmentId}`, { department_content_image }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    const response = await mainRequest;
    await Promise.all(imageRequests);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteDepartment = async (departmentId) => {
  try {
    const response = await axiosAuthorized.delete(`/department/delete_department/${departmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}