import React, {  useMemo } from "react";
import "./NavBar.css";
import { BlackBarSvg } from "../../svg";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { NavDrop } from "./NavDrop/NavDrop";


const NavBar = () => {
  const user = useSelector((state) => state.userReducer.admin);
  const links = useMemo(
  () => user.pages.map((page) => {return { to: `/${page==="Analytics" ? "" :page.toLowerCase()}`, destination: `${page}` }}),
  [user]
);

  return (
    <>
      <div className="navbar relative">
        <div className="logo-container">
          <div className="logo">
            <img src="/assets/logo/life_plus_logo.png" alt="life_plus_logo" />
          </div>
          <BlackBarSvg />
        </div>
        <div className="navlinks ">
          {links.map(({ to, destination }, index) => (
            ["Admins","Career","About"].includes(destination) ? "" 
            :
            <NavLink to={to} key={index} className="navlink-class">
              <div>{destination}</div>
              <div className="active-dot"></div>
            </NavLink>
          ))}
        </div>
        <div className="flex">
          <NavDrop user={user} links={links} />
        </div>
      </div>
    </>
  );
};

export default NavBar;
