import { axiosAuthorized } from "../../api/config";

export const getTestominals = async () => {
    try {
        const response = await axiosAuthorized.get("/gettestinomal");
        return response;
    } catch (error) {
        throw error;
    }
}

export const addTestominal = async (initialState, testominalId = null) => {
    try {
        const requestData = {
            ...initialState
        };

        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };

        const url = testominalId
            
            ? `/testinomal/update_testinomal/${testominalId}`
            : `/testinomal/add_testinomal`;

        const response = await axiosAuthorized[testominalId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteTestominal = async (testominalId) => {
    try {
        const response = await axiosAuthorized.delete(`/testinomal/delete_testinomal/${testominalId}`);
        return response;
    } catch (error) {
        throw error;
    }
}