import React, { useCallback, useEffect, useState } from 'react';
import { CiStickyNote } from "react-icons/ci";
import {  SearchInput } from '../Common';
import { getBlogs } from '../../utils/blogsUtils/blogsUtils';
import BlogsTable from './BlogsTable';
import BlogsModal from './BlogsModal';
import { useSelector } from 'react-redux';

const BlogsMainSection = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [blogs, setBlogs] = useState([]);
    const setBlogsFn = useCallback(() => {
      getBlogs()
        .then((res) => setBlogs(res.data.data))
        .catch((err) => console.log(err));
  },[]);
  useEffect(() => {
    setBlogsFn();
  }, [setBlogsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = blogs?.filter((blog) => {
      const nameMatch = blog?.blog_title?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const departmentMatch = blog?.blog_category?.department
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || departmentMatch ;
    });
    setFilteredData(filteredData);
  }, [blogs, searchQuery]);
  const user = useSelector((state) => state.userReducer.admin);
    return (
      <>
        {open && (
          <BlogsModal
            setBlogsFn={setBlogsFn}
            setEditData={setEditData}
            setOpen={setOpen}
            editData={editData}
          />
        )}
        <div className=" flex w-full items-center gap-5 md:gap-0">
          <div className="flex w-fit h-fit text-xl font-semibold">Blogs</div>
          <div className="flex w-full h-full justify-center items-center">
            <div className="flex w-full md:w-1/2">
              <SearchInput
                placeholder={"Search By Name, Department"}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full p-3 flex-col">
          <div className="flex w-full justify-between items-center h-fit">
            <span className="font-semibold text-base">Informations</span>
            <button
              disabled={!user.access.includes("Edit")}
              className={`bg-secondary text-white ${
                !user.access.includes("Edit") ? "hidden" : "flex"
              } text-sm rounded-md gap-2 p-2 active:bg-secondary  items-center`}
              onClick={() => setOpen(true)}
            >
              <span>
                <CiStickyNote />
              </span>
              <span>Blog</span>
            </button>
          </div>
          <BlogsTable
            user={user}
            blogs={filterdData}
            setBlogsFn={setBlogsFn}
            setEditData={setEditData}
            setOpen={setOpen}
          />
        </div>
      </>
    );
}

export default BlogsMainSection