import { axiosAuthorized } from "../../api/config";

export const getFooterData = async () => {
    try {
        const response = await axiosAuthorized.get(`/footer/get_homepagefooter`);
        return response.data.data[0];
    } catch (error) {
        throw error
    }
}

export const addFooterData = async (initialState, hospital_logo, social_media_links,department, id = null) => {
    try {
        const requestData = {
            ...initialState,
            hospital_logo,
            department,
            social_media_links
        }
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };
        const url = id
            ? `/footer/update_homepagefooter/${id}`
            : `/footer/add_homepagefooter`;
        const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
};
