import React, { useState } from 'react'
import { useForm } from '../../hooks';
import { GrFormClose } from 'react-icons/gr';
import { ImageInput, ShowImage, TextAreaInput } from '../Common/FormComponents';
import { toast } from 'react-toastify';
import { validateCallUsPoint } from '../../utils/callUsPointsUtils/validateCallUsPoint';
import { addCallUsPoint } from '../../utils/callUsPointsUtils/callUsPointsUtils';
const CallUsPointModal = ({ setOpen, editData = null,setEditData, setCallUsPointsFn }) => {
    const callUsPointInitialState = {
      home_description: editData?.home_description || "",
    };
    const [callUsPointData, handleCallUsPointData, clearForm] = useForm(callUsPointInitialState);
    const [homepage_icon, setImage] = useState(editData?.homepage_icon || null);
    const [err, setErr] = useState({ global: "" });
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
      if (validateCallUsPoint(callUsPointData, homepage_icon, setErr)) {
        setErr({ global: "" });
        setLoading(true);
        addCallUsPoint(callUsPointData, homepage_icon, editData?._id)
          .then((res) => {
            toast(`Point ${editData ? "Edited" : "Added"}`, {
              type: "success",
              autoClose: 3000,
            });
              setCallUsPointsFn();
              setImage(null);
              clearForm();
              setEditData(null);
            setOpen(false);
          })
          .catch((err) => {
            console.log(err);
            toast(`Point ${editData ? "edit failed" : "add failed"}`, {
              type: "error",
              autoClose: 3000,
            });
          })
          .finally(() => setLoading(false));
      }
    };
  return (
    <div className="flex bg-white   top-0 left-0 w-full max-w-screen-sm overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
      <div className="flex w-full h-fit  items-center justify-between font-semibold">
        <span>{editData ? "Edit Point" :"Add new Point"}</span>
        <span
          className="cursor-pointer"
          onClick={() => {
            setOpen(false);
            setEditData(null);
          }}
        >
          <GrFormClose fontSize={16} />
        </span>
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextAreaInput
          name={"Description"}
          formName={"home_description"}
          data={callUsPointData}
          handleInput={handleCallUsPointData}
          setError={setErr}
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <ShowImage image={homepage_icon} setImage={setImage} />
        <ImageInput imageName={"Point Icon"} setImage={setImage} />
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5">
        <div className=" flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            Cancel
          </button>
        </div>
        <div className="flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
            disabled={loading}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default CallUsPointModal