import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import treatmentReducer from "./treatmentSlice";
import userReducer from "./userSlice";

const persistConfig = {
    key: "root",
    storage
}

const persistTreatmentReducer = persistReducer(persistConfig, treatmentReducer);
const persistUserReducer = persistReducer(persistConfig, userReducer);


export const store = configureStore({
    reducer: {
        treatmentReducer: persistTreatmentReducer,
        userReducer: persistUserReducer
    },
    middleware:[]
})

export const persistor = persistStore(store);