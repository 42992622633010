import React, { useMemo } from 'react'
import { Table } from '../Common';
import { TbUserEdit } from 'react-icons/tb';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { deleteAdmin } from '../../utils/adminUtils/adminUtils';

const AdminsTable = ({user,setOpen,setEditData, admins, setAdminsFn}) => {
  const headings = useMemo(() =>{ return !user?.is_master ? ["Name","A-ID","Contact","Email","Department","Category"] : ["Name","A-ID","Contact","Email","Department","Category","Edit","Delete"]}, [user]);
  const delAdmin = (adminId) => {
    deleteAdmin(adminId)
      .then(res => setAdminsFn())
      .catch(err => console.log(err));
  }  
  return (
    <Table headings={headings}>
      {admins.map((admin, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center gap-4">
              <div className="flex-shrink-0 h-9 w-9 justify-center items-center border rounded-full flex">
                <img
                  className="rounded-full h-9 w-9 object-cover"
                  src={admin?.admin_photo}
                  alt="admin_profile"
                />
              </div>
              <div className="text-sm font-medium text-gray-900">
                {admin?.name}
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{admin?.admin_id}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{admin?.phone_number}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{admin?.email_id}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {admin?.department || "Master Admin"}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {admin?.category || "Master Admin"}
            </div>
          </td>
          {user?.is_master && (
            <>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    setEditData(admin);
                    setOpen(true);
                  }}
                >
                  <TbUserEdit />
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize cursor-pointer">
                  <button onClick={()=>delAdmin(admin?._id)} disabled={admin?.is_master}>
                    <MdOutlineDeleteOutline color="red" />
                  </button>
                </div>
              </td>
            </>
          )}
        </tr>
      ))}
    </Table>
  );
}

export default AdminsTable