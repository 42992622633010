import { axiosAuthorized } from "../../api/config";

export const getAllMembers = async () => {
    try {
        const response = await axiosAuthorized.get(`/about/get_about`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addMember = async (initialState, profile_picture, past_company_logo,category, meta_tag, memberId = null) => {
    try {
        const requestData = {
            name: initialState.name,
            role: initialState.role,
            meta_name: initialState.meta_name,
            experience: initialState.experience,
            meta_description: initialState.meta_description,
            meta_tag,
            profile_picture,
            category,
            past_company_logo
        }
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };
        const url = memberId
            ? `/about/update_about/${memberId}`
            : `/about/create_about`;
        const response = await axiosAuthorized[memberId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getOurTeam = async () => {
    try {
        const response = await axiosAuthorized.get(`/about/our_team`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getOurAdvisoryBoard = async () => {
    try {
        const response = await axiosAuthorized.get(`/about/our_advisory_Board`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteMember = async (memberId) => {
    try {
        const response = await axiosAuthorized.delete(`/about/delete_about/${memberId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getProblemsWeAreSolving = async () => {
    try {
        const response = await axiosAuthorized.get(`/icon/problem_solving`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getOurServices = async () => {
    try {
        const response = await axiosAuthorized.get(`/icon/our_service`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getOurValues = async () => {
    try {
        const response = await axiosAuthorized.get(`/icon/our_values`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSocial = async () => {
    try {
        const response = await axiosAuthorized.get(`/icon/social_channel`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const addIcon = async (initialState, icon, icon_category, meta_tag, iconId = null) => {
    try {
        const requestData = {
            icon_name: initialState.icon_name,
            meta_name: initialState.meta_name,
            icon_category,
            meta_description: initialState.meta_description,
            meta_tag,
            icon,
            link: initialState?.link || ""
        }
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };
        const url = iconId
            ? `/icon/update_icon/${iconId}`
            : `/icon/create_icon`;
        const response = await axiosAuthorized[iconId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}


export const deleteIcon = async (iconId) => {
    try {
        const response = await axiosAuthorized.delete(`/icon/delete_icon/${iconId}`);
        return response;
    } catch (error) {
        throw error;
    }
}
export const getNumbers = async () => {
    try {
      const response = await axiosAuthorized.get(`/getlifeplusnumber`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const addNumber = async (initialState, numberId = null) => {
    try {
        const requestData = {
            ...initialState
        }
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const url = numberId
          ? `/lifeplusnumber/update_lifeplusnumber/${numberId}`
            : `/lifeplusnumber/add_lifeplusnumber`;
        const response = await axiosAuthorized[numberId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}


export const deleteNumber = async (numberId) => {
    try {
      const response = await axiosAuthorized.delete(`/lifeplusnumber/delete_lifeplusnumber/${numberId}`);
        return response;
    } catch (error) {
        throw error;
    }
}